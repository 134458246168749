<template>
  <div>
    <v-dialog v-model="modal" scrollable persistent max-width="800px">
      <v-card>
        <v-card-title class="headline indigo darken-4 white--text" primary-title
          >Mediciones</v-card-title
        >
        <v-card-text style="height: 400px">
          <v-container v-if="modal">
            <v-row>
              <v-row>
                <v-col cols="12">
                  <v-divider></v-divider>
                  <v-subheader class="font-weight-bold"
                    >Datos de Registro</v-subheader
                  >
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="barco.registration_number"
                    hint="Número de registro"
                    persistent-hint
                    readonly
                    number
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="barco.boat_name"
                    hint="Nombre de la Embarcación"
                    persistent-hint
                    readonly
                    number
                  ></v-text-field>
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    :value="
                      barco.registration_port ||
                      (
                        (concepto.items_select_registration_port || []).find(
                          (x) => x.abbr == barco.registration_port
                        ) || {}
                      ).state
                    "
                    hint="Puerto de registro"
                    persistent-hint
                    readonly
                    number
                  ></v-text-field>
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    :value="
                      barco.boat_type_state || (barco.boat_type || {}).nombre
                    "
                    hint="Tipo de barco"
                    persistent-hint
                    readonly
                    number
                  ></v-text-field>
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    :value="
                      barco.current_state_pavilion != null
                        ? concepto.items_select_current_state_pavilion.find(
                            (x) => x.abbr == barco.current_state_pavilion
                          ) != undefined
                          ? concepto.items_select_current_state_pavilion.find(
                              (x) => x.abbr == barco.current_state_pavilion
                            ).state
                          : ''
                        : ''
                    "
                    hint="Estado del pabellón"
                    persistent-hint
                    readonly
                    number
                  ></v-text-field>
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    :value="barco.owner_name"
                    hint="Propietario"
                    persistent-hint
                    readonly
                    number
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-divider></v-divider>
                  <v-subheader class="font-weight-bold"
                    >Dimensiones</v-subheader
                  >
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="barco.length_overall"
                    readonly
                    prepend-inner-icon
                    placeholder="ESLORA - Longitud total (LOA)(m)"
                    hint="ESLORA - Longitud total (LOA)(m)"
                    persistent-hint
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="barco.calado"
                    readonly
                    prepend-inner-icon
                    placeholder="Calado / Proyecto (m)"
                    hint="Calado / Proyecto (m)"
                    persistent-hint
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="barco.beam_extreme_breadth"
                    readonly
                    prepend-inner-icon
                    placeholder="Manga / extrema amplitud (m)"
                    hint="Manga / extrema amplitud (m)"
                    persistent-hint
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="barco.net_registered_tonnage"
                    readonly
                    prepend-inner-icon
                    hint="Tonelaje de Registro Neto (TRN)"
                    persistent-hint
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="barco.gross_registered_tonnage"
                    prepend-inner-icon
                    readonly
                    hint="Tonelaje de Registro Bruto (TRB)"
                    persistent-hint
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="
                      (
                        (concepto.items_select_propulsion_type || []).find(
                          (x) => x.abbr == barco.type_of_propulsion
                        ) || {}
                      ).state
                    "
                    readonly
                    hint="Tipo de propulsión."
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="barco.main_engines_power"
                    readonly
                    prepend-inner-icon
                    placeholder="Potencia del motor principal/s"
                    hint="Potencia del motor principal/s"
                    persistent-hint
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="barco.power_unit_state || barco.power_unit"
                    readonly
                    q21
                    º
                    hint="Unidad de Potencia"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="
                      barco.hull_material_state ||
                      (
                        (concepto.items_select_helmet_material || []).find(
                          (x) => x.abbr == barco.hull_material
                        ) || {}
                      ).state
                    "
                    readonly
                    hint="Material casco"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="barco.warehouses_number"
                    readonly
                    prepend-inner-icon
                    placeholder="Numero de bodegas"
                    hint="Numero de bodegas"
                    persistent-hint
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="barco.fish_hold_high"
                    readonly
                    prepend-inner-icon
                    placeholder="Alto de la bodega de pescado (m)"
                    hint="Alto de la bodega de pescado (m)"
                    persistent-hint
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="barco.fish_hold_long"
                    readonly
                    prepend-inner-icon
                    placeholder="Largo de la bodega de pescado (m)"
                    hint="Largo de la bodega de pescado (m)"
                    persistent-hint
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="barco.fish_hold_width"
                    readonly
                    placeholder="Ancho de la bodega de pescado (m)"
                    hint="Ancho de la bodega de pescado (m)"
                    persistent-hint
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="barco.fish_hold_capacity"
                    readonly
                    placeholder="Capacidad de la bodega de pescado (m³)"
                    hint="Capacidad de la bodega de pescado (m³)"
                    persistent-hint
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="
                      barco.fish_hold_type_state ||
                      (
                        (
                          concepto.items_select_type_conservation_fish || []
                        ).find((x) => x.abbr == barco.fish_hold_type) || {}
                      ).state
                    "
                    readonly
                    hint="Tipo de conservación del pescado"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="3"></v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-divider></v-divider>
                  <v-subheader class="font-weight-bold"
                    >Construcción</v-subheader
                  >
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    :value="barco.year_of_construction"
                    hint="Año de construcción"
                    persistent-hint
                    readonly
                    number
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    :value="barco.construction_country"
                    hint="País de construcción"
                    persistent-hint
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-divider></v-divider>
                  <v-subheader class="font-weight-bold"
                    >Patente de Pesca</v-subheader
                  >
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="barco.date_expedition_patent"
                    hint="Fecha de expedición de la patente"
                    persistent-hint
                    readonly
                    number
                  ></v-text-field>
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    :value="barco.date_expiration_patent"
                    hint="Fecha de vencimiento de la patente"
                    persistent-hint
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="barco.number_of_crew"
                    hint="Numero de tripulantes"
                    persistent-hint
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="barco.number_of_fishermens"
                    hint="Numero de pescadores"
                    persistent-hint
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="6" v-if="(barco.type_fishery || []).length">
                  <v-list>
                    <v-list-group>
                      <template v-slot:activator>
                        <v-list-item-content>
                          <v-list-item-title
                            >Tipo de pesquería</v-list-item-title
                          >
                        </v-list-item-content>
                      </template>
                      <v-list-item
                        v-for="item in barco.type_fishery"
                        :key="item.id"
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.pivot.description"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-group>
                  </v-list>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-divider></v-divider>
                  <v-subheader class="font-weight-bold"
                    >Artes de Pesca</v-subheader
                  >
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="6">
                  <v-list>
                    <v-list-group>
                      <template v-slot:activator>
                        <v-list-item-content>
                          <v-list-item-title>Artes de Pesca</v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <v-list-item
                        v-for="item in barco.fishing_arts != undefined
                          ? barco.fishing_arts.length > 0
                            ? barco.fishing_arts
                            : []
                          : []"
                        :key="item.id"
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.pivot.description"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-group>
                  </v-list>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    :value="barco.fishing_arts_value"
                    hint="Valor del arte de pesca ($)"
                    persistent-hint
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    :value="barco.fishing_zone"
                    hint="Área de operaciones"
                    persistent-hint
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  v-show="
                    barco.fishing_arts.find((x) => x.codigo == 'DOS') !=
                    undefined
                  "
                >
                  <v-subheader
                    light
                    class="subtitle-1 font-italic font-weight-bold"
                  >
                    {{
                      barco.fishing_arts.find((x) => x.codigo == "UNO") !=
                      undefined
                        ? barco.fishing_arts.find((x) => x.codigo == "UNO")
                            .nombre
                        : ""
                    }}
                  </v-subheader>
                  <v-divider light></v-divider>
                </v-col>
                <v-col
                  cols="3"
                  sm="12"
                  md="3"
                  v-show="
                    barco.fishing_arts.find((x) => x.codigo == 'UNO') !=
                    undefined
                  "
                >
                  <v-text-field
                    :value="
                      barco.fishing_line_state != null
                        ? barco.fishing_line_state
                        : concepto.items_select_type_line.find(
                            (x) => x.abbr == barco.fishing_line
                          ) != undefined
                        ? concepto.items_select_type_line.find(
                            (x) => x.abbr == barco.fishing_line
                          ).state
                        : ''
                    "
                    hint="Denominación del arte de pesca tipo Sedal ó de anzuelo"
                    persistent-hint
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="3"
                  sm="12"
                  md="3"
                  v-show="
                    barco.fishing_arts.find((x) => x.codigo == 'UNO') !=
                    undefined
                  "
                >
                  <v-text-field
                    :value="
                      barco.hook_type_state != null
                        ? barco.hook_type_state
                        : concepto.items_select_type_hook.find(
                            (x) => x.abbr == barco.hook_type
                          ) != undefined
                        ? concepto.items_select_type_hook.find(
                            (x) => x.abbr == barco.hook_type
                          ).state
                        : ''
                    "
                    hint="Tipo de anzuelo"
                    persistent-hint
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="3"
                  sm="12"
                  md="3"
                  v-show="
                    barco.fishing_arts.find((x) => x.codigo == 'UNO') !=
                    undefined
                  "
                >
                  <v-text-field
                    :value="barco.hook_size"
                    hint="Tamaño de anzuelo"
                    persistent-hint
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="3"
                  sm="12"
                  md="3"
                  v-show="
                    barco.fishing_arts.find((x) => x.codigo == 'UNO') !=
                    undefined
                  "
                >
                  <v-text-field
                    readonly
                    :value="barco.hooks_number"
                    hint="Cantidad de anzuelos"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="3"
                  sm="12"
                  md="3"
                  v-show="
                    barco.fishing_arts.find((x) => x.codigo == 'UNO') !=
                    undefined
                  "
                >
                  <v-text-field
                    readonly
                    :value="barco.mother_line_length"
                    hint="Longitud de la línea madre medida en brazas"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="3"
                  sm="12"
                  md="3"
                  v-show="
                    barco.fishing_arts.find((x) => x.codigo == 'UNO') !=
                    undefined
                  "
                >
                  <v-text-field
                    readonly
                    :value="
                      barco.mother_line_material_state != null
                        ? barco.mother_line_material_state
                        : concepto.items_select_mother_line_material.find(
                            (x) => x.abbr == barco.mother_line_material
                          ) != undefined
                        ? concepto.items_select_mother_line_material.find(
                            (x) => x.abbr == barco.mother_line_material
                          ).state
                        : ''
                    "
                    hint="Material de la línea madre"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="3"
                  sm="12"
                  md="3"
                  v-show="
                    barco.fishing_arts.find((x) => x.codigo == 'UNO') !=
                    undefined
                  "
                >
                  <v-text-field
                    readonly
                    :value="
                      barco.downpipe_material_state != null
                        ? barco.mother_line_material_state
                        : concepto.items_select_downspout_material.find(
                            (x) => x.abbr == barco.downpipe_material
                          ) != undefined
                        ? concepto.items_select_downspout_material.find(
                            (x) => x.abbr == barco.downpipe_material
                          ).state
                        : ''
                    "
                    hint="Material de las bajantes"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="3"
                  sm="12"
                  md="3"
                  v-show="
                    barco.fishing_arts.find((x) => x.codigo == 'UNO') !=
                    undefined
                  "
                >
                  <v-text-field
                    readonly
                    :value="barco.total_lines_number"
                    hint="Cantidad total de líneas"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  v-show="
                    barco.fishing_arts.find((x) => x.codigo == 'DOS') !=
                    undefined
                  "
                >
                  <v-subheader
                    light
                    class="subtitle-1 font-italic font-weight-bold"
                  >
                    {{
                      barco.fishing_arts.find((x) => x.codigo == "DOS") !=
                      undefined
                        ? barco.fishing_arts.find((x) => x.codigo == "DOS")
                            .nombre
                        : ""
                    }}
                  </v-subheader>
                  <v-divider light></v-divider>
                </v-col>
                <v-col
                  cols="3"
                  sm="12"
                  md="3"
                  v-show="
                    barco.fishing_arts.find((x) => x.codigo == 'DOS') !=
                    undefined
                  "
                >
                  <v-text-field
                    readonly
                    :value="
                      barco.art_type_traps_state != null
                        ? barco.art_type_traps_state
                        : concepto.items_select_denomination_art_fishing.find(
                            (x) => x.abbr == barco.art_type_traps
                          ) != undefined
                        ? concepto.items_select_denomination_art_fishing.find(
                            (x) => x.abbr == barco.art_type_traps
                          ).state
                        : ''
                    "
                    hint="Denominación del arte de pesca tipo trampas o nasas"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="3"
                  sm="12"
                  md="3"
                  v-show="
                    barco.fishing_arts.find((x) => x.codigo == 'DOS') !=
                    undefined
                  "
                >
                  <v-text-field
                    readonly
                    v-model="barco.type_traps_number"
                    hint="Cantidad de trampas o nasas"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="3"
                  sm="12"
                  md="3"
                  v-show="
                    barco.fishing_arts.find((x) => x.codigo == 'DOS') !=
                    undefined
                  "
                >
                  <v-text-field
                    readonly
                    :value="
                      barco.type_traps_material_state != null
                        ? barco.type_traps_material_state
                        : concepto.items_select_main_trap_material.find(
                            (x) => x.abbr == barco.type_traps_material
                          ) != undefined
                        ? concepto.items_select_main_trap_material.find(
                            (x) => x.abbr == barco.type_traps_material
                          ).state
                        : ''
                    "
                    hint="Material principal de la trampa o nasa"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  v-show="
                    barco.fishing_arts.find((x) => x.codigo == 'TRES') !=
                    undefined
                  "
                >
                  <v-subheader
                    light
                    class="subtitle-1 font-italic font-weight-bold"
                  >
                    {{
                      (barco.fishing_arts.find((x) => x.codigo == "TRES") || {})
                        .nombre
                    }}
                  </v-subheader>
                  <v-divider light></v-divider>
                </v-col>
                <v-col
                  cols="3"
                  sm="12"
                  md="3"
                  v-show="
                    barco.fishing_arts.find((x) => x.codigo == 'TRES') !=
                    undefined
                  "
                >
                  <v-text-field
                    readonly
                    :value="
                      barco.type_wound_artifacts_state != null
                        ? barco.type_wound_artifacts_state
                        : concepto.items_select_type_artifact.find(
                            (x) => x.abbr == barco.type_wound_artifacts
                          ) != undefined
                        ? concepto.items_select_type_artifact.find(
                            (x) => x.abbr == barco.type_wound_artifacts
                          ).state
                        : ''
                    "
                    hint="Tipo de artefactos de herir o aferrar"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="3"
                  sm="12"
                  md="3"
                  v-show="
                    barco.fishing_arts.find((x) => x.codigo == 'TRES') !=
                    undefined
                  "
                >
                  <v-text-field
                    readonly
                    v-model="barco.amount_artifacts"
                    label="Cantidad de artefactos"
                    hint="Cantidad de artefactos"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="3"
                  sm="12"
                  md="3"
                  v-show="
                    barco.fishing_arts.find((x) => x.codigo == 'TRES') !=
                    undefined
                  "
                >
                  <v-text-field
                    readonly
                    :value="
                      barco.artifacts_material_state != null
                        ? barco.artifacts_material_state
                        : concepto.items_select_artifact_material.find(
                            (x) => x.abbr == barco.artifacts_material
                          ) != undefined
                        ? concepto.items_select_artifact_material.find(
                            (x) => x.abbr == barco.artifacts_material
                          ).state
                        : ''
                    "
                    hint="Tipo de artefactos de herir o aferrar"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-divider light></v-divider>
                </v-col>
                <v-col cols="3" sm="12" md="3">
                  <v-text-field
                    readonly
                    :value="
                      barco.type_fad_state != null
                        ? barco.type_fad_state
                        : concepto.items_select_type_fad.find(
                            (x) => x.abbr == barco.type_fad
                          ) != undefined
                        ? concepto.items_select_type_fad.find(
                            (x) => x.abbr == barco.type_fad
                          ).state
                        : ''
                    "
                    hint="Tipo de FAD utilizados"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="3" sm="12" md="3">
                  <v-text-field
                    readonly
                    :value="barco.fad_amount"
                    hint="Cantidad de FAD utilizados"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="12" md="6">
                  <v-text-field
                    readonly
                    :value="barco.fad_components"
                    hint="Componentes del FAD"
                    persistent-hint
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-divider></v-divider>
                <v-subheader class="font-weight-bold"
                  >Fotos de la Embarcación</v-subheader
                >
                <v-divider></v-divider>
              </v-col>

              <v-col cols="12">
                <v-gallery
                  :images="images"
                  :index="index"
                  @close="index = null"
                >
                </v-gallery>
              </v-col>
              <v-col
                cols
                class="image"
                v-for="(image, imageIndex) in images"
                :key="imageIndex"
                @click="index = imageIndex"
                :style="{
                  backgroundImage: 'url(' + image + ')',
                  width: '300px',
                  height: '150px',
                  backgroundSize: 'contain',
                }"
              ></v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="modal = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modal_matricula" scrollable persistent max-width="800px">
      <v-card>
        <v-card-title class="headline indigo darken-4 white--text" primary-title
          >Agregar Matricula</v-card-title
        >
        <v-card-text style="height: 220px">
          <v-container>
            <v-row>
              <v-col cols="5">
                <v-text-field
                  v-model="nombre_boat"
                  label="Nombre Embarcación"
                  :disabled="barco.boat_name != null"
                ></v-text-field>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  v-model="matricula_add"
                  :disabled="
                    barco.registration_number != null &&
                    barco.registration_number != ''
                  "
                  label="No de Matrícula"
                  @keyup="Valid()"
                ></v-text-field>
                <span v-if="!matricula_valid"
                  >La matrícula no debe contener caracteres especiales ni
                  espacios ejemplo: (CP07002)</span
                >
              </v-col>
              <v-col cols="5">
                <v-btn
                  color="green"
                  class="mt-5 ml-2 white--text"
                  :disabled="!matricula_valid"
                  @click="editBoat()"
                >
                  Agregar
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
          <!--small>*indicates required field</small-->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="modal_matricula = false"
            >Cerrar</v-btn
          >

          <!-- <v-btn color="indigo darken-4 white--text" dark @click="saveCompany">Crear</v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" top="top" color="primary">
      Este permisionario ya tienen una solicitud a la espera de ser resuelta
      <v-btn dark text @click="snackbar = false">Cerrar</v-btn>
    </v-snackbar>
    <v-card class="mx-auto">
      <v-data-table
        :headers="headers"
        :items="arrayDataNew"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"

        class="elevation-1"
      >

        <template v-slot:top>
            <v-toolbar flat color="white">
            <v-toolbar-title>Medición</v-toolbar-title>

            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>

            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{}">
                <!--v-btn color="indigo darken-4 white--text" dark class="mb-2" v-on="on">Nuevo</v-btn-->

                <!-- <v-btn color="indigo darken-4 white--text" dark class="mb-2" @click="newFisherman">
                  <v-icon left small>fa-plus</v-icon>Nuevo
                </v-btn>-->
                <v-spacer></v-spacer>
                <export-excel
                  :data="arrayDataNew"

                  worksheet="Mediciones"
                  name="Mediciones.xls"
                  style="ml-4 margin-bottom:10px"
                >
                  <v-btn
                    color="green darken-4 ml-4 white--text"
                    small
                    dark
                    class="mb-2"
                  >
                    <v-icon small class="mr-2">fa fa-file-excel</v-icon>
                    Exportar
                  </v-btn>
                  <!--v-icon>file-excel</v-icon-->
                </export-excel>
                <v-text-field
                  v-model="searchBoat"
                 @input="searchData(searchBoat)"
                  append-icon="search"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </template>
            </v-dialog>
            <v-dialog
              eager
              v-model="dialogRad"
              scrollable
              persistent
              width="1200px"
            >
              <v-card>
                <v-card-text>
                  <v-container>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="dialogRad = false"
                        >Cerrar</v-btn
                      >
                    </v-card-actions>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-toolbar>
     <v-menu v-model="showMenu">
            <template v-slot:activator="{ on }">
              <v-btn class="ml-4 mb-4" v-on="on">
                  {{ name_filter_type }}
                <v-icon>filter_list</v-icon>
              </v-btn>
            </template>

            <v-list>

              <v-list-item
                 v-for="type in arrayDataArts"
                :key="type.description"
                style="cursor: pointer"
              >
                <v-list-item-content
                  @click="filterPer('type', type.description, type.description)"
                >
                  <v-list-item-title> {{ type.description }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item style="cursor: pointer">
                <v-list-item-content
                  @click="filterPer('type', 'Todos', 'Todos')"
                >
                  <v-list-item-title>Todos</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>

        </template>
          <template v-slot:item.zonapeces="{ item  }">
              <span
                v-for="item_ in item.zonapeces"
                :key="item_.id"
              >
                {{item_.specie}}
                <br>

                </span>
            </template>




        <!--template v-slot:no-data>
          <v-btn color="primary" @click="initialize">Reset</v-btn>
        </template-->
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { boatImageUrl, editBoatUrl } from "../config";
import {

} from "../services/DataSourceService";

export default {
  props: ["type"],
  components: {
    // ComponentRadicado,
  },
  data: () => ({
    modal: false,
    modal_matricula: false,
    path: boatImageUrl,
    token:"",

    barco: { fishing_arts: [], images: [] },
    Boats: [],
    arrayData: [],
    arrayDataNew: [],
    json_fields: {},
    concepto: {},
    searchBoat: "",
    options: "",
    sortBy: "boat_name",
    sortDesc: true,
    index: null,
    radios: "SI",
    dialog: false,
    dialogRad: false,
    snackbar: false,
    showMenu: false,
    showMenu_v: false,
    showMenu_p: false,
    name_filter_vigency: "Vigencia del Permiso",
    name_filter_type: "Zona",
    name_filter_patent: "Vigencia de la Patente",
    date_issue: new Date().toISOString().substr(0, 10),
    date_start_period: new Date().toISOString().substr(0, 10),
    date_end_period: new Date().toISOString().substr(0, 10),
    date_notification: new Date().toISOString().substr(0, 10),
    menu1: false,
    menu2: false,
    menu3: false,
    menu4: false,
    headers_a: [
      { text: "Nombre de la Embarcación", value: "boat_name" },
      {
        text: "No. Matricula",
        align: "left",
        sortable: false,
        value: "registration_number",
      },
      // { text: "Bandera", value: "bandera" },
      { text: "Permiso", value: "permiso" },
      { text: "Acciones", value: "action" },

      // Nombre de la Embarcación / No. Matricula / Bandera / Tipo de Pesquería / Artes de Pesca /
    ],
    headers: [
      { text: "#", value: "order" },

      {
        text: "Sitio",
        align: "left",
        value: "sitio",
      },
      // { text: "Bandera", value: "bandera",   },
      { text: "Zona", value: "zona"},
      { text: "Arte", value: "art"},
      {
        text: "Peces Totales",
        value: "zonapeces",
        sortable: false,
      },
      { text: "Creado por", value: "createBy"},
      // { text: "Artes. Pesca ", value: "artes",  width: "15" },
      //{ text: "Fecha de Registro", value: "permiso"},

      { text: "Fecha de Creación", value: "createdAt"},
      { text: "Fecha Actualización", value: "updatedAt"},

      // Nombre de la Embarcación / No. Matricula / Bandera / Tipo de Pesquería / Artes de Pesca /
    ],
    desserts: [],
    arrayDataArts: [],
    editedIndex: -1,
    filtrado: [],
    matricula_add: "",
    nombre_boat: "",
    _id: "",
    matricula_valid: false,
    listaBarcos: [],
    allCompanies: [],
  }),



  watch: {
    dialog(val) {
      val || this.close();
    },
    type: async function (newV) {
      this.listBoats(newV);
    },
  },

  created: async function () {
    this.getToken();
    if (localStorage.getItem('dataNew') !== null) {
      this.arrayDataNew = JSON.parse(localStorage.getItem('dataNew'));
      }else{
          this.arrayData = this.arrayDataNew;
      }

  },

  methods: {
     updateLocalStorage(){
            localStorage.setItem('dataNew', JSON.stringify(this.arrayDataNew));
        },
    searchData(value){
        this.arrayDataNew = JSON.parse(localStorage.getItem('dataNew'));
        this.filtrado = this.arrayDataNew.filter(e => e.art.toLowerCase().includes(value)|| e.zona.toLowerCase().includes(value)||e.sitio.toLowerCase().includes(value));
        this.arrayDataNew = this.filtrado;
      },
   async listFishingArt(){

     await fetch('https://ssayp-backend.rgpsai.org/v1/api/fishing-art/list?limit=250&active=true&limit=1000', {
        method: 'GET',
        headers: {
        'Content-Type': 'application/json',
        'Authorization':this.token,
        },

      })
      .then(response => response.json())
      .then(data => {
       // console.log('Success:', data);
        //this.arrayData = data;
          this.arrayDataArts = data.items.map((item,index)=>{
          return {
            description: item.description,
            index:index+1,
          }
        })
      })
      .catch((error) => {
         // eslint-disable-next-line no-console
        console.error('Error:', error);
      });
    },
    async getToken(){
        var data = {
        'email':'admin2@sasas.com',
        'password':'a123456789'
    };
     await fetch('https://ssayp-backend.rgpsai.org/v1/api/auth/login', {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then(response => response.json())
      .then(data => {
      //  console.log('Success:', data.token);
        this.token = data.token;
        this.listMeasurement();
        this.listFishingArt();
      })
    },
    async listMeasurement(){

     await fetch('https://ssayp-backend.rgpsai.org/v1/api/measurement/report?limit=1000', {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        'Authorization':this.token,
        },

      })
      .then(response => response.json())
      .then(data => {
        //console.log('Success:', data);
        //this.arrayData = data;
          this.arrayDataNew = data.items.map((item,index)=>{

          return {

            zona: item.zone.description,
            sitio: item.site.description,
            art: item.art.description,
            createBy: item.create_by.email,
            createdAt: item.createdAt,
            updatedAt: item.updatedAt,
            zonapeces:item.measured_specie,
            order:index+1,

          }
        })
        this.updateLocalStorage();
       // console.log(this.arrayData);
      })
      .catch((error) => {
         // eslint-disable-next-line no-console
        console.error('Error:', error);
      });
    },

    async listBoats(type) {
      if (type === "ind") {
        !this.getTodosBarcosInd.length &&
          (await this.$store.dispatch("getBarcos", {
            type: type.toUpperCase(),
          }));
        !this.allCompaniesInd?.solicitudes?.length &&
          (await this.$store.dispatch("getCompanies", { type: "DOS" }));
        this.filtrado = this.getTodosBarcosInd;
        this.listaBarcos = this.getTodosBarcosInd;
        this.allCompanies = this.allCompaniesInd;
      }
      if (type === "art") {
        !this.getTodosBarcosArt.length &&
          (await this.$store.dispatch("getBarcos", {
            type: type.toUpperCase(),
          }));
        !this.allCompaniesArt?.solicitudes?.length &&
          (await this.$store.dispatch("getCompanies", { type: "UNO" }));
        this.filtrado = this.getTodosBarcosArt;
        this.listaBarcos = this.getTodosBarcosArt;
        this.allCompanies = this.allCompaniesArt;
      }
    },
    Valid() {
      const pattern = /^[a-zA-Z0-9]+$/;
      this.matricula_add = this.matricula_add.toUpperCase();
      this.matricula_valid = pattern.test(this.matricula_add);
    },
    setItem(item) {
      this.barco = item;
      this.modal_matricula = true;
      this._id = item.id;
      this.nombre_boat = item.boat_name;
      this.matricula_add = item.registration_number;
    },
    editBoat: async function () {
      if (
        this.nombre_boat.length == 0 &&
        this.registration_number.length == 0
      ) {
        return false;
      }
      await this.axios
        .put(editBoatUrl + this._id, {
          boat_name: this.nombre_boat,
          registration_number: this.matricula_add,
        })
        .then(() => {
          this.nombre_boat = "";
          this.matricula_add = "";
          this._id = "";
        });
      this.$store.dispatch("getBarcos", { type: this.type.toUpperCase() });
      this.filtrado = this.listaBarcos;
      this.modal_matricula = false;
    },
    filterPer(type, value, name_filter) {

      this.arrayDataNew = JSON.parse(localStorage.getItem('dataNew'));
      this.filtrado = this.arrayDataNew;
      this.name_filter_type = name_filter;

      if (value == "Todos") {
        this.listMeasurement();
      }
      else{
        var filtrado=this.filtrado.filter((x) => x.art== value);
        this.arrayDataNew = filtrado;
        this.filtrado = [];
        this.filtrado = this.arrayDataNew;
      }
    },
    pesqueria(permissions) {
      const permission = permissions.slice(-1).pop();

      if (permission != undefined) {
        return permission.type_fishery;
      } else {
        return [];
      }
    },
    seeItem(item) {
      this.barco.fishing_arts = item.fishing_arts;
      this.barco = item;
      this.modal = true;
    },
    item_permission(permissions) {
      const permission = permissions.slice(-1).pop();
      if (permission) {
        if (permission.effective_inactivation_date) {
          return permission.effective_inactivation_date;
        } else {
          return "Sin Permiso";
        }
      } else {
        return "Sin Permiso";
      }
    },
    permission(permissions) {
      if (permissions === undefined) return "invalid";
      const permission = permissions.slice(-1).pop();
      if (permission !== undefined) {
        let date1 = new Date(permission.effective_inactivation_date);
        let date2 = new Date();
        const status =
          date1.getMonth() -
          date2.getMonth() +
          12 * (date1.getFullYear() - date2.getFullYear());

        if (permission.effective_inactivation_date == null) {
          return "invalid";
        } else if (status > 0 && status <= 2) {
          return "alert";
        } else if (
          permission.effective_inactivation_date >
            new Date().toISOString().substr(0, 10) &&
          permission.estado == 1
        )
          return "active";
        else if (
          permission.effective_inactivation_date <
          new Date().toISOString().substr(0, 10)
        ) {
          return "expired";
        } else if (permission.estado === 2) {
          return "canceled";
        }
      } else {
        return "invalid";
      }
    },

    patente(fecha) {
      if (fecha === null) return "invalid";
      let date1 = new Date(fecha);
      let date2 = new Date();
      const status =
        date1.getMonth() -
        date2.getMonth() +
        12 * (date1.getFullYear() - date2.getFullYear());

      if (status > 0 && status <= 1) {
        return "alert";
      }
      if (fecha > new Date().toISOString().substr(0, 10)) return "active";
      else if (fecha < new Date().toISOString().substr(0, 10)) {
        return "expired";
      }
    },

    Peticion: function (action, item, title) {
      const verif_req = this.allCompanies.solicitudes.find(
        (value) => value.company_id == item.company_id && value.estado < 2
      );
      if (verif_req !== undefined) {
        this.dialogRad = false;
        this.snackbar = true;
      } else {
        this.dialogRad = true;
        this.$refs.setDataRad.setData(action, item, title, this.type);
      }
    },
    viewItem(item) {
      //
      this.$router.push({
        path: `/view-boat/${item.identification_number}`,
      });
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    limiter(e) {
      if (e.length > 4) {
        //
        e.pop();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
